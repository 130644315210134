import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Hero from "../components/Hero"
// import NewsletterSignUp from "../components/NewsletterSignUp"
import BlogListHome from "../components/BlogListHome"
import Banner from "../components/Banner"
// import CTA from "../components/CTA"
import AboutTheCompany from "../components/AboutTheCompany"
import AboutUs from "../components/AboutUs"

const pageQuery = graphql`
  {
    posts: allGraphCmsPost(
      limit: 3
      sort: { order: DESC, fields: publishedAt }
    ) {
      nodes {
        excerpt
        publishedAt
        slug
        tags
        title
        coverImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 400, quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        content {
          markdownNode {
            childMdx {
              timeToRead
            }
          }
        }
        author {
          name
          title
          picture {
            localFile {
              childImageSharp {
                fixed(width: 40, quality: 90) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`

const IndexPage = () => {
  const { posts } = useStaticQuery(pageQuery)
  return (
    <Layout page="home">
      <SEO meta={{ title: "Hallowed Hollows" }} />
      {/* <Banner
        to="/products"
        text="Big news! We're excited to announce a brand new product."
      /> */}
      <Hero />
      <AboutTheCompany />
      <AboutUs />
      {/* <NewsletterSignUp></NewsletterSignUp> */}
      {/* <CTA /> */}

      <BlogListHome posts={posts.nodes} page="home" />
    </Layout>
  )
}

export default IndexPage
