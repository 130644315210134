import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import Img from "gatsby-image"
import * as dayjs from "dayjs"

export default function BlogCard({ post }) {
  console.log(post)
  return (
    <div
      className="w-full flex flex-col items-start rounded-lg shadow-lg cursor-pointer"
      key={post.slug}
      onClick={() => navigate(`/posts/${post.slug}`)}
      role="button"
    >
      {/* <img
        src={post.coverImage.url}
        alt={`${post.title} cover image`}
        className="rounded-t-lg object-cover h-56 w-full"
      /> */}
      <Img
        fluid={post.coverImage.localFile.childImageSharp.fluid}
        className="rounded-t-lg object-cover h-56 w-full"
      />
      <div className="px-5 pb-5">
        <div className="flex my-4 flex-wrap justify-evenly">
          {post.tags.map((tag, i) => {
            return (
              <span
                key={i}
                className="mt-2 py-1 px-3 rounded bg-olive-100 text-olive-500 text-xs font-bold tracking-widest"
              >
                {tag.toUpperCase()}
              </span>
            )
          })}
        </div>
        <h2 className="text-gray-900 font-bold text-2xl lg:text-3xl leading-snug mt-2 mb-4 font-serif">
          {post.title}
        </h2>

        <p className="leading-relaxed mb-8 text-gray-600">{post.excerpt}</p>
        <div className="inline-flex items-center">
          {/* <img
            alt="blog"
            src={
              post.author.picture
                ? post.author.picture.url
                : "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"
            }
            className="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"
          /> */}
          <Img
            fixed={post.author.picture.localFile.childImageSharp.fixed}
            className="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center"
          ></Img>
          <span className="flex-grow flex flex-col pl-4">
            <span className="title-font font-semibold text-gray-900">
              {post.author.name}
            </span>
            <span className="text-gray-500 text-sm">
              {dayjs(post.publishedAt).format("MMMM D, YYYY")} &#8226;{" "}
              {post.content?.markdownNode &&
                `${post.content.markdownNode.childMdx.timeToRead} min read`}
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}

BlogCard.propTypes = {
  post: PropTypes.object.isRequired,
}
