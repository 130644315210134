import React from "react"
// import { graphql, useStaticQuery } from "gatsby"

// const imageQuery = graphql`
//   query {
//     heroImage: file(relativePath: { eq: "hollows.jpg" }) {
//       childImageSharp {
//         fixed(width: 1280, height: 400, quality: 80) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//   }
// `

export default function AboutTheCompany() {
  // const { heroImage } = useStaticQuery(imageQuery)
  return (
    <>
      <section className="bg-olive-800 py-16 text-center text-olive-100">
        <div className="container mx-auto flex flex-col items-center">
          <h1 className="mb-6 text-6xl font-black font-serif tracking-wider">
            Hallowed Hollows
          </h1>
          <p className="text-3xl tracking-tight font-serif italic">
            Hallowed: made holy{" "}
          </p>
          <p className="text-3xl tracking-tight font-serif mb-8 italic">
            Hollows: a wooded area; usually a valley
          </p>
          <div className="mx-10 max-w-screen-sm ">
            <p className="tracking-wide text-lg leading-10 mb-5 text-left">
              Hallowed Hollows name was formed to represent Holy Land or God’s
              land.
            </p>
            <p className="tracking-wide text-lg leading-10 text-left mb-5">
              With this intent in mind; Christ is always what we want to be the
              message in everything we do! So when this jamming adventure
              started, it was in mind of a bigger plan and dream. A God sized
              dream. What has come out of it, is a love for making jams and
              jellies, pastries, and so much more.{" "}
            </p>
            <p className="tracking-wide text-lg leading-10 text-left mb-5">
              With God at the forefront, we want to take Hallowed Hollows and
              use it as a way of bringing community together. We plan on using
              as much local produce, from nearby farms ,as we can. There will be
              a recycling program to help, not only keep your cost down, but to
              be environmentally conscious. Plans to introduce several new
              products in the next few weeks are with the intent of the holidays
              coming up.
            </p>
            <p className="tracking-wide text-lg leading-10 text-left mb-5">
              We want to bring family (and friends) back around the table.
              Whether it be a game night, snacks at a holiday party, appetizers
              on Thanksgiving day, we hope to bring joy and togetherness into
              your homes with our products. Each item truly is homemade with
              love from us to you. We are excited for this new journey and hope
              you travel along with us.
            </p>
          </div>
        </div>
      </section>
    </>
  )
}
