import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Heading from "./Heading"

const imageQuery = graphql`
  query {
    jandbImage: file(relativePath: { eq: "jandb.jpg" }) {
      childImageSharp {
        fixed(width: 800, height: 600) {
          originalName
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default function AboutUs() {
  const { jandbImage } = useStaticQuery(imageQuery)
  return (
    <section className="bg-olive-100 pt-24 pb-16 lg:pb-24">
      <div className="container mx-auto px-5 ">
        <div className="flex flex-col lg:flex-row lg:space-x-10">
          <div className="flex flex-col w-full lg:w-1/3">
            <Heading textAlign="left">About The Albrittons</Heading>
            <p className="text-gray-500 text-lg lg:text-lg tracking-wide mb-3">
              John and Brittany Albritton currently reside in Calera, Alabama.
              They married in March of 2020 after meeting in January of 2019.
              They considered themselves best friends and each other’s biggest
              supporters. Both are all about family. Together, they live in a
              1960’s fixer upper, with their cat Little Bit. Here they can be
              found working on the house and working on dreams they have for not
              only them, their families, but also the community around them.
            </p>
            <p className="text-gray-500 text-lg lg:text-lg tracking-wide mb-3">
              John is a parts Manager at a car dealership. He has worked on cars
              for the past 15 years. He is quiet in nature, but has many
              talents. He is actually to be known as the better chef! He enjoys
              wood work, playing the drums, tinkering on computers, gardening,
              and completing the many tasks that Brittany gives him.
            </p>
            <p className="text-gray-500 text-lg lg:text-lg tracking-wide">
              Brittany is a RN and is currently working at an aesthetics office.
              She loves the medical world and it will always be a part of her,:
              but as Hallowed Hollows grows, she has decided to dedicate her
              time to it fully. She has a passion for bringing people together
              and plans to use this platform in many ways to do just that. If
              you can’t find her involved with the business, you will probably
              find her listening to music, playing with animals, or planning
              some new area to decorate.
            </p>
          </div>
          <div className="w-full lg:w-2/3 my-10 lg:my-0">
            <div className="h-full flex flex-col items-start justify-start">
              <Img
                alt="John and Brittany Profile"
                fixed={jandbImage.childImageSharp.fixed}
                className="max-w-full h-auto flex-shrink-0 rounded-lg object-cover object-center sm:mb-0 mb-4 shadow-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
