import React from "react"
import PropTypes from "prop-types"
import BlogCard from "./BlogCard"
import Heading from "./Heading"

export default function BlogListHome({ posts }) {
  return (
    <section className="text-gray-700 body-font overflow-hidden">
      <div className="container px-5 py-24 mx-auto">
        <Heading>B’s Blogs</Heading>
        <p className="text-gray-500 mb-12 text-center leading-10 text-xl">
          Where an ordinary life meets extraordinary dreams
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          {posts.map(post => {
            return <BlogCard post={post} key={post.slug} />
          })}
        </div>
      </div>
    </section>
  )
}

BlogListHome.propTypes = {
  posts: PropTypes.array.isRequired,
}
